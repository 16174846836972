import React from 'react';
import { navigate } from 'gatsby';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import whoami from '../svg/whoami.jpg';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Lynx Consulting
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Developing scalable applications to fit your needs
          </p>
          <p className="mt-8 md:mt-12" onClick={() => navigate('/Contact')}>
            <Button size="lg">Get Started</Button>
          </p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p className="mt-4 text-gray-600">Let's put your business on a new level.</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Our process</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Research</p>
              <p className="mt-4">
                We do research into your industry in order to best serve your needs and help you
                increase your market share.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Develop</p>
              <p className="mt-4">
                We use an iterative method of developing applications that allows for feedback and
                rapid improvement.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Deploy</p>
              <p className="mt-4">
                We deploy your application to the cloud allowing for scalability and security. Your
                data security matters.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Research and Understanding</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of motivated engineers take time to understand your business needs and how we
            can help you.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Design And Develop</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            When we have a gained a full grasp of your companies needs, we start a process of
            development called Agile that allows us to iterate quickly on your application and make
            changes swiftly according to your needs.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Deploy and Optimize</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            After the application is live, we will continuously look for ways to optimize and test
            your application in order to be the best that it can be.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">This is me!</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            My name is Austin Miller! I started Lynx a few years ago in order to branch out and drive my creativity. I want to
            create a relationship of trust with my clients and really push to do my best work on
            every project.
          </p>
        </div>
      }
      secondarySlot={<img src={whoami} />}
    />
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light" />
      <p className="mt-8" onClick={() => navigate('/Contact')}>
        <Button size="xl">Get Started Now</Button>
      </p>
    </section>
  </Layout>
);
